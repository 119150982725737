"use strict";

/**
 * 前端作者:李文杰
 * 联系方式（微信）:liwenjieoh666
 * 联系方式（qq）:820876312
 */
// 检测屏幕设备
(function ($) {
  var MIN_CONTENT_WIDTH = 1440;
  var MOBILE_BREAKPOINT = 800;
  window.layout = {
    get: function get() {
      var viewport = $(window).width();

      if (viewport <= MOBILE_BREAKPOINT) {
        return 'mobile';
      }

      return 'desktop';
    },
    watch: function watch(callback) {
      callback(layout.get());
      $(window).on('smartresize orientationchange', function () {
        callback(layout.get());
      });
    }
  };
  $(window).on('resize', debounce(function () {
    $(window).trigger('smartresize');
  }, 50));
})(jQuery);

var appGlobal = {
  wowInit: function wowInit() {
    if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
      new WOW().init();
    }

    ;
  },
  imgLazyLoad: function imgLazyLoad() {
    $('.lazyload').lazyload();
  },
  numUp: function numUp() {
    $('.couter').numIncrement();
  },
  doAnimate: function doAnimate() {
    $('.anim').animInit();
  },
  globalInit: function globalInit() {
    this.wowInit();
    this.imgLazyLoad();
    this.numUp();
    this.doAnimate();
  }
};
$(function () {
  appGlobal.globalInit();
}); //jquery拓展方法

$.fn.extend({
  // 三级导航事件
  navHk: function navHk() {
    $(this).hover(function () {
      $(this).children("ul").slideDown();
      $(this).find("li").each(function () {
        $(this).hover(function () {
          $(this).children(".third").stop().slideDown();
          $(this).siblings().children(".third").stop().hide();
        });
      });
    }, function () {
      $(this).children("ul").stop().slideUp();
    });
  },
  notEmpty: function notEmpty(el) {
    if ($(el).legnth > 0) {
      return true;
    } else {
      return false;
    }
  },
  renderSwiper: function renderSwiper(opt) {
    return new Swiper($(this), opt);
  },

  /**
   * @activeClass 高亮样式类名
   * @childNode 赋给高亮样式的目标元素类名
   */
  hoverActive: function hoverActive(opt) {
    var defaults = {
      activeClass: "active",
      targetNode: ".item"
    };
    var opts = $.extend(defaults, opt);
    var origIdx = $(this).find(opts.targetNode + "." + opts.activeClass).index();
    var items = $(this).find(opts.targetNode);
    items.hover(function () {
      $(this).addClass(opts.activeClass).siblings().removeClass(opts.activeClass);
    }, function () {
      items.eq(origIdx).addClass(opts.activeClass).siblings().removeClass(opts.activeClass);
    });
  },
  // hover高亮横线跟随
  hoverFlowMenu: function hoverFlowMenu(opt) {
    var defaults = {
      item: ".item",
      targetFlowEl: '.flowBar',
      activeClass: "active"
    };
    var opts = $.extend(defaults, opt);
    var navOffLeft = $(this).offset().left;

    if ($(this).find(opts.item + "." + opts.activeClass).length == 1) {
      var origOffLeft = $(this).find(opts.item + "." + opts.activeClass).offset().left - navOffLeft;
      var origIdx = $(this).find(opts.item + "." + opts.activeClass).not(".bdr").index();
      var origW = $(this).find(opts.item + "." + opts.activeClass).innerWidth();
      $(opts.targetFlowEl).css({
        width: origW
      });
      var item = $(this).find(opts.item);
      setTimeout(function () {
        $(opts.targetFlowEl).css({
          left: origOffLeft
        });
      }, 10);
      item.hover(function () {
        var offLeft = $(this).offset().left;
        var w = $(this).innerWidth();
        setTimeout(function () {
          $(opts.targetFlowEl).css({
            left: offLeft - navOffLeft,
            width: w
          });
        }, 10);
        $(this).addClass(opts.activeClass).siblings().removeClass(opts.activeClass);
      }, function () {
        setTimeout(function () {
          $(opts.targetFlowEl).css({
            left: origOffLeft,
            width: origW
          });
        }, 10);
        item.eq(origIdx).addClass(opts.activeClass).siblings().removeClass(opts.activeClass);
      });
    }
  },
  // 网页复制功能
  copyMsg: function copyMsg(callback) {
    var _this = $(this);

    var data = _this.attr("data-copy");

    _this.click(function () {
      var aux = document.createElement("input");
      aux.setAttribute("value", data);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      callback(data); // alert("✔已成功复制微信号（" + data + " 何先生），赶紧打开微信，粘贴添加好友，免费询价吧 ⊙∀⊙");
    });
  },
  scrollUpTop: function scrollUpTop(opt) {
    var defaults = {
      speed: 800
    };
    var opts = $.extend(opt, defaults);
    $(this).each(function () {
      $(this).click(function () {
        $('html, body').stop(true, true).animate({
          'scrollTop': 0
        }, opts.speed);
      });
    });
  },
  // 数字递增特效
  numIncrement: function numIncrement() {
    if ($(this).length) {
      $(this).counterUp({
        delay: 1,
        time: 250
      });
    }

    ;
  },
  // amim自定义动画
  animInit: function animInit() {
    // 出场动画
    var anim = $(this); //'.anim'

    setTimeout(function () {
      //规避页面加载不完整高度获取异常
      $(window).scroll(function () {
        rollAni();
      });
      $(window).resize(function () {
        rollAni();
      });
      rollAni();
    }, 10);

    function rollAni() {
      //滚动执行动画
      var oHeight = $(window).height();
      var ScrVal = $(window).scrollTop();
      anim.each(function (i) {
        if (ScrVal + oHeight > anim.eq(i).offset().top) {
          anim.eq(i).addClass("anim-show"); //添加相应的animage.css中的动画,使用animation属性
        }
      });
    }
  },
  // 子元素跟父元素等高
  elementEqualHeight: function elementEqualHeight(opt) {
    var defaults = {
      container: '.equal-container',
      element: '.equal-element'
    };
    var opts = $.extend(defaults, opt);

    if (opts.equal) {
      setTimeout(function () {
        $(opts.container).each(function () {
          var $this = $(this);

          if ($this.find(opts.element).length) {
            $this.find(opts.element).css({
              'height': 'auto'
            });
            var elem_height = 0;
            $this.find(opts.element).each(function () {
              var this_elem_h = $(this).height();

              if (elem_height < this_elem_h) {
                elem_height = this_elem_h;
              }
            });
            $this.find(opts.element).height(elem_height);
          }
        });
      }, 50);
    }

    ;
  }
});
var Share = {
  //微信
  weixin: function weixin() {
    // 保存二维码的容器
    var qrcode = new QRCode('setQrcode', {
      text: window.location.href,
      width: 200,
      height: 200,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H
    });
  },
  //新浪微博
  weibo: function weibo() {
    window.open("http://service.weibo.com/share/share.php?title=" + share_Title + "&url=" + window.location.href + "&pic=" + share_Img);
  },
  //qq好友
  sqq: function sqq() {
    window.open("https://connect.qq.com/widget/shareqq/index.html?title=" + share_Title + "&url=" + window.location.href);
  },
  //QQ空间
  qq_zone: function qq_zone() {
    window.open("http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?title=" + share_Title + "&summary=" + share_Info + "&url=" + window.location.href + "&pics=" + share_Img);
  }
}; // 节流函数

function debounce(func, wait, immediate) {
  var timeout;
  return function tick() {
    var context = this,
        args = arguments;

    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

; // $('#copy').copyMsg(function(data){
//   alert("✔已成功复制微信号（"+data+" 何先生），赶紧打开微信，粘贴添加好友，免费询价吧 ⊙∀⊙");
// })